/* A module containing most of the constants that we want to define for this project.
See sources.js for constants relating to Mapbox map tile sources, and layers.js for
constants relating to Mapbox map layers.
*/

//eRoadMAP token
const mapboxglToken =
    'pk.eyJ1IjoiZXJvYWRtYXAiLCJhIjoiY2xueGk3cWE5MGg5cjJtcGNiNXNueHk0cCJ9.kpagtuqAcxV_gZLb1SUxxA';

// global constants for elements that have to be adjusted based on zoom level
const maxMapZoom = 18;

// IMPORTANT: the values here must be manually kept in sync with the set in the
// eroadmapdata.demand(z, x, y, year) function on the database
const hexResolutionBreaks = [
    { zoom: 7, hex: 5 },
    { zoom: 8, hex: 6 },
    { zoom: 10, hex: 7 },
    { zoom: maxMapZoom, hex: 8 },
];

// The actual areas, in square miles, that the hexagons have at each resolution,
// converted from https://h3geo.org/docs/core-library/restable/#average-area-in-km2
const hexAreas = {
    9: '0.041',
    8: '0.28',
    7: '2',
    6: '14',
    5: '98',
    4: '684',
    3: '4785',
    2: '33514',
};

// this palette is for years 2024-2030
const categoryColors = {
    years: ['#fdf4cd', '#f3e28f', '#c1e6a2', '#6bd09d', '#298ed1'],
    fullElectrification: [
        '#fdf4cd',
        '#c1e6a2',
        '#299feb',
        '#1e6695',
        '#00131f',
    ],
};

const fastPortColor = '#CC00F7';
const softBlack = '#202020'; // 000000 with white text is actually hard to look at

// Greyscale ramp
const hostingCapacityColors = [
    '#aaaaaa',
    '#8c8c8c',
    '#686868',
    '#454545',
    softBlack,
];

// this is a subset of all the colors used - specifically, the ones that need white text
// against them to make it readable
const darkCategoryColors = [
    '#299feb',
    '#298ed1',
    '#1e6695',
    '#00131f',
    '#8c8c8c',
    '#686868',
    '#454545',
    softBlack,
    fastPortColor,
];

const categoryBreaks = {
    years: {
        // This is the hand-crafted set of artisinal breaks for use on 2024-2030 data
        5: [10.0, 20.0, 60.0, 150.0, 12000],
        6: [8.0, 25.0, 50.0, 75.0, 6115],
        7: [1.0, 3.0, 9.0, 18.0, 2795],
        8: [0.5, 1.0, 3.0, 7.5, 1785],
    }, // This is the set of breaks to be used in conjunction with the fullElectrification palette above;
    // it was originally devloped by Watson Collins but was modified by us so that the first two stops
    // at each hex level correspond to the 2nd and 4th stops in the set of breaks above for 2024-2030
    fullElectrification: {
        5: [20, 150, 3920.0, 7840.0, 12000],
        6: [25, 75, 560.0, 1120.0, 6115],
        7: [3, 18, 80.0, 160.0, 2795],
        8: [1, 7.5, 32.0, 64.0, 1785],
    },
};

const hostingCapacityBreaks = [0, 1, 1.5, 2.0];

const minValueForHexDisplay = 0.001;
const staticHexTilesMaxZoom = 10;
const staticCensusTractsTilesMaxZoom = 8;
const hostingCapacityPolygonMaxZoom = 10;

// global variables to configure data layers and display
const satelliteStyleId = 'eroadmap/clxmb3pf100e401o737pphkvc';
const satelliteStyleName = 'EPRI satellite basemap';
const roadStyleId = 'eroadmap/clx3keuhd01qz01po6r7a0bvm';
const roadStyleName = 'eRoadMAP base with boundaries';

// Because our hexes look nicer at different opacities with the different map styles
const roadHexFillOpacity = 0.75;
const satelliteHexFillOpacity = 0.6;

const hexAttribute = 'energy_total'; // this is the attribute from the hexes we are using to derive quintile breakpoints

const windowHref = window.location.href;
const href = windowHref.substring(0, windowHref.lastIndexOf('/'));
const staticTilesBase = href + '/tiles/demand';
let dynamicTilesBase = href + '/tileserver';

// always use dev tile server for preview deployments
if (href.includes('7888')) {
    dynamicTilesBase = 'http://localhost:7800';
} else if (href.includes('localhost') || href.includes('github.io')) {
    dynamicTilesBase = 'https://eroadmaptiles-dev.epri.com';
}

const hexOutlineStyleRule = [
    'step',
    ['get', 'energy_total'],
    categoryColors['years'][0],
    minValueForHexDisplay,
    '#ffffff',
];

const defaultFillOpacity = 0.3;


// the median percentage of dwellings that are multifamily in a census tract in this data set
const mfd_percent_MEDIAN = 0.1599;
// max is actually 100%! Hello, Manhattan.

// the median percentage of households that are cost burdened in a census tract in this data set
const cost_burdened_MEDIAN = 0.228;
// max is unfortunately 100%.

const pm25_MIN_VALUE = 9.0; // minimum acceptable value set by the EPA
const pm25_MAX_VALUE = 17.75; // rounded
const layerGradientColors = ['#FBB800', '#9000f7'];

// Given a variable in the data and two ends of its range, build a color gradient
// rule to display it.
function layerGradientColorRule(keyVariable, minValue, maxValue) {
    return [
        'interpolate',
        ['linear'],
        ['get', keyVariable],
        0,
        '#ffffff',
        minValue,
        layerGradientColors[0],
        maxValue,
        layerGradientColors[1],
    ];
}

//set bounds to entire USA
const bounds = [
    [-173, 12], // southwest coords
    [-20, 71], // northeast coords
];

// contact email address in a form that at least less sophisticated scrapers won't manage to parse
// will be populated by the ready() function
const emailPrefix = 'eRoadMAP';
const emailDomain = 'epri.com';

// the mapbox layer IDs to put our layers under / behind
const hexLayerBeforeId = 'null-island';
const dataLayerBeforeId = 'road-label';

// Validation functions for url parameters values passed in to this page. These need to
// be kept in sync with the values in the UI in index.html - or the values in index.html
// could be dynamically populated from these in the future somehow.
const paramValidators = {
    year: (value) =>
        ['2024', '2025', '2026', '2027', '2028', '2029', '2030'].includes(
            value
        ), // keep this the same as the slider range in index.html
    fullElectrification: (value) => ['true', 'false'].includes(value),
    layer: (value) =>
        [
            // keep this in sync with the layer-select options in index.html
            'pm25',
            'ev_charging',
            'hosting_capacity',
            'justice40',
            'multifamily',
            'transportation',
            'truck_stops',
            'cost_burdened_households',
        ].includes(value),
    center: (value) => {
        const boundsObj = new mapboxgl.LngLatBounds(bounds);
        value = value.split(',').map((x) => parseFloat(x, 10));
        return !value.includes(NaN) && boundsObj.contains(value);
    },
    zoom: (value) => {
        value = parseInt(value, 10);
        return value >= 0 && value <= maxMapZoom;
    },
};

export {
    bounds,
    categoryBreaks,
    categoryColors,
    cost_burdened_MEDIAN,
    darkCategoryColors,
    dataLayerBeforeId,
    defaultFillOpacity,
    dynamicTilesBase,
    emailDomain,
    emailPrefix,
    fastPortColor,
    hexAreas,
    hexAttribute,
    hexLayerBeforeId,
    hexOutlineStyleRule,
    hexResolutionBreaks,
    hostingCapacityBreaks,
    hostingCapacityColors,
    hostingCapacityPolygonMaxZoom,
    mapboxglToken,
    maxMapZoom,
    mfd_percent_MEDIAN,
    minValueForHexDisplay,
    layerGradientColorRule,
    layerGradientColors,
    pm25_MAX_VALUE,
    pm25_MIN_VALUE,
    roadHexFillOpacity,
    roadStyleId,
    roadStyleName,
    satelliteHexFillOpacity,
    satelliteStyleId,
    softBlack,
    staticHexTilesMaxZoom,
    staticCensusTractsTilesMaxZoom,
    staticTilesBase,
    paramValidators,
};
